.change-pwd {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 30%;
        min-width: 300px;

        div {
            display: flex;
            color: $color-3;
            justify-content: space-between;
            width: 100%;

            margin: 15px;

            @media screen and (max-width: 960px) {
                flex-direction: column;
            }

            label {
                margin: 10px;
            }

            input {
                padding: 3px;;
                border-radius: 5px;
            }
        }
    }
}