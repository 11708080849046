.navbar {
  background: $color-3;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 999;

  .container {
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 80px;

    @media screen and (max-width: 960px) {
      padding-right: 40px;
      z-index: 99;
    }
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    .logo-img {
      margin-left: 80px;
      height: 60px;
      @media screen and (max-width: 960px) {
        height: 60px;
        margin-left: 40px;
      }
    }

    .menu-icon {
      display: none;
      width: 40px;

      @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
        -moz-transform: translate(-100%, 60%);
        -ms-transform: translate(-100%, 60%);
        -o-transform: translate(-100%, 60%);
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        margin-right: 0.5rem;
        margin-top: 0.5em;
      }

      .menu-line {
        width: 80%;
        height: 4px;
        border-radius: 1em;
        transition: 0.5s;
        position: relative;

        &.menu-line-bottom {
          background-color: $color-2;
          left: 20%;
        }

        &.menu-line-middle {
          background-color: $color-1;
          margin: 5px 0;
          left: 0;
        }

        &.menu-line-top {
          background-color: $color-2;
          left: 20%;
        }
      }
    }

    .nav-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      text-align: center;
      justify-content: end;

      @media screen and (max-width: 960px) {
        flex-direction: column;
        justify-content: start;
        width: 100vw;
        margin: 0;
        padding: 0;
        height: 90vh;
        position: absolute;
        top: 80px;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 1;
        transition: all 0.5s ease;

        &.active {
          background: $color-3;
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
          transition: all 0.6s ease;
          z-index: 1;
        }
      }

      .nav-contact {
        @media screen and (max-width: 960px) {
          margin-top: 80px;
        }
      }

      .nav-item {
        height: 80px;
        border-bottom: 2px solid transparent;

        .real-estate {
          @media screen and (max-width: 960px) {
            display: none !important;
          }
        }

        .nav-item__properties {
          background-color: white;
          opacity: 0;
          border-radius: 0 0 10px 10px;

          @media screen and (max-width: 960px) {
            opacity: 100;
          }

          .nav-links {
            &:hover {
              position: relative;
              bottom: 2px;
            }
          }
        }

        &:hover {
          border-bottom: 4px solid $color-1;

          .nav-item__properties {
            opacity: 100;
          }
        }

        @media screen and (max-width: 960px) {
          width: 100%;

          &:hover {
            border: none;
          }
        }

        .nav-links {
          color: $color-2;
          display: flex;
          align-items: center;
          text-decoration: none;
          padding: 0.5rem 1rem;
          height: 100%;

          @media screen and (max-width: 960px) {
            text-align: center;
            padding: 2rem;
            width: 100%;
            display: table;

            &:hover {
              color: $color-1;
              transform: scale(1.2);
              transition: all 0.3s ease;
            }
          }
        }
      }
    }

    .social-media {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-top: 3px solid transparent;
      }

      @media screen and (max-width: 960px) {
        position: relative;
      }

      .social-media__items {
        @media screen and (max-width: 960px) {
          display: flex;
          justify-content: center;
        }

        li {
          color: $color-2;
          font-size: 28px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding-top: 5px;

          a {
            all: unset;
            cursor: pointer;
            margin: 0 10px;
          }
        }
      }
    }
  }
}
