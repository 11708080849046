$color-1: #bce4db;
$color-2: #003753;
$color-3: #fff;
$color-4: #032130;

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;

  font-family: "Montserrat", sans-serif;

  &::-webkit-media-controls {
    display: none!important;
    -webkit-appearance: none;
  }



  &::selection {
    color: $color-3;
    background: $color-2;
  }
}

*::-webkit-media-controls-panel {

  display: none!important;
  -webkit-appearance: none;

}

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;

}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

body { 
  background-color: #032130;
}

.section {
    position: relative;
    bottom: 0;
    min-height: 100vh;
    padding-top: 80px;
    overflow: hidden;
}

.background-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 960px) {
    display: none;
  }

  filter: opacity(20%);

  &.services-img {
    object-position: left bottom;
    bottom: 0px;
  }

  &.contact-img {
    object-position: left bottom;
    bottom: 0;
  }
}
