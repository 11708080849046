.footer {
    height: fit-content;
    min-height: 130px;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: $color-3;
    color: $color-2;

    padding: 5px;


    .footer__data {
        display: flex;
        justify-content: space-around;
        align-items: center;
        

        div {
            text-align: center;
            font-size: small;
            margin: 10px;
        }

        .footer__address {
            font-size: large;
        }

        @media screen and (max-width: 960px) {
            flex-direction: column-reverse;
        }
    }

    .footer__mentions {

        display: flex;
        font-size: 12px;
        margin: 3px;

        @media screen and (max-width: 960px) {
            font-size: 10px;
            justify-content: center;
        }

        .footer__rights {
            display: flex;
            align-items: center;

            span {
                margin-left: 3px;
            }
        }

        .footer__links {
            a, a:visited, a:hover, a:active {
                color: $color-2;

                span {
                    font-weight: bold;
                }
            }
            
        }
    }

}