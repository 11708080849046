.principles {
    
    display: flex;
    justify-content: space-around;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    padding: 5vw;

    color: $color-2;

    .principles__half {
        flex: 1;
    }

    @media screen and (max-width: 960px) {
        flex-direction: column;
        position: relative;
        top: 0;
        left: 0;
        transform: inherit;
        margin-top: 80px;

        .principles__half {
            width: 100%;
        }
    }

    .slick-dots {
        button::before {
            color: white !important;
        }
    }

    .slick-track {
        display: flex;
      }
      .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
      }
  
      .slick-slider {
        max-width: 35vw;
  
        @media screen and (max-width: 960px) {
          max-width: 90vw;
        }
      }

    .principles__card {
        // background-color: rgb(255, 255, 255);
        color: white;
        text-align: justify;
        backdrop-filter: opacity(0%);
        margin: 20px 0;
        padding: 15px 30px;
        border-radius: 10px;
        width: 100%;
        // position: relative;
        overflow: hidden;
        line-height: calc(17px + 0.390625vw);

        h3 {
            margin-bottom: 10px;
            font-size: calc(15px + 0.390625vw);
        }

        p {
            font-size: calc(14px + 0.390625vw);
        }
    }

    .img {
        width: 100%;
        border-radius: 10px;
    }
 
}