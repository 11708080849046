.form-property {

    position: absolute;
    z-index: 900;

    top: 0;

    width: 100vw;
    height: 100vh;
    min-height: 568px;
    backdrop-filter: blur(5px);

    display: flex;
    align-items: center;
    justify-content: center;

    .form-container {

        height: 70%;
        width: 70%;

        display: grid;
        grid-template-columns: 1fr 1fr;
        border-radius: 10px;

        @media screen and (max-width: 1300px) {
            grid-template-columns: none;
            grid-template-rows: 1fr 1fr;;
            overflow: scroll;
        }

        

        .form-container__files {
            background: linear-gradient($color-1, $color-2);
            overflow: scroll;
            border-radius: 10px 0 0 10px;
            padding: 10px;

            .rug-handle {
                height: 230px;

                @media screen and (max-width: 1300px) {
                    height: 170px;
                }
            }  
        }

        .form-container__data {
            background-color: $color-3;
            overflow: scroll;
            border-radius: 0 10px 10px 0;
            padding: 10px;

            h3 {
                text-align: center;
            }

            .form {

                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }
                /* Firefox */
                input[type=number] {
                -moz-appearance: textfield;
                }

                display: flex;
                flex-direction: column;

                .form__group {
                    border: 3px solid $color-2;
                    border-radius: 10px;
                    padding: 5px;
                    margin: 5px 0;

                    .input {
                        border: 1px solid $color-2;
                        border-radius: 4px;
                        padding: 3px;
                        margin: 3px;

                        &.missing {
                            border: 2px solid red;
                        }

                        &.description {
                            overflow: scroll;
                            resize: none;
                        }
                    }

                    label {
                        margin: 3px;
                    }
                }

                .form__description {
                    display: flex;
                    flex-direction: column;


                }

                .form__type {

                    display: flex;
                    justify-content: space-around;
                    text-align: center;

                    @media screen and (max-width: 1300px) {
                        flex-direction: column;
                    }


                    .form__type__radioButton {
                        display: flex;
                        justify-content: space-between;

                        @media screen and (max-width: 1300px) {
                            flex-direction: column;
                            align-items: center;
                        }

                        div {
                            display: flex;
                            padding: 3px;
                            
                            input {
                                margin: 0 10px;
                            }
                        }
                    }
                }

                .form__price {
                    display: flex;
                    justify-content: space-around;

                    @media screen and (max-width: 1300px) {
                        flex-direction: column;
                    }

                    div {
                        @media screen and (max-width: 960px) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                    }
                }

                .form__address {
                    display: flex;
                    flex-direction: column;

                    .form__address__group  {
                        display: flex;
                        justify-content: space-around;

                        @media screen and (max-width: 1300px) {
                            flex-direction: column;
                        }

                        div {
                            @media screen and (max-width: 1300px) {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }

                        .form__address__street {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .input {
                                width: 60%;

                                @media screen and (max-width: 1300px) {
                                    width: auto;
                                }
                            }
                        }
                    }
                }

                .form__interior {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    div {
                        display: flex;
                        justify-content: space-between;
                        width: 80%;
                        align-items: center;

                        @media screen and (max-width: 1300px) {
                            flex-direction: column;
                            width: 90%;
                        }

                        &.form__interior__peb {
                            justify-content: center;
                            
                        }
                    }
                    
                }

                button {
                    border: 3px solid $color-2;
                    margin: 5px 0;
                    padding: 5px;
                    border-radius: 10px;
                    cursor: pointer;
                    background-color:$color-2;
                    color: $color-3;
                    font-weight: bold;

                    &:hover {
                        letter-spacing: 1px;
                        background-color: $color-3;
                        color: $color-2;
                    }
                }
            }
        }
    }
}