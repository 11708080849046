.admin-page {
    padding: 0;
    height: 100vh;
    min-height: 568px;
}

.admin-page__options {
    background-color: $color-3;
    width: fit-content;
    padding: 15px 20px;
    border-radius: 0 0 10px 10px;
    margin: auto;
    height: 30px;

    display: flex;
    align-items: center;

    border: 2px solid $color-2;
    border-width: 0 2px 2px 2px;

    .text {
        margin: 0 10px;
        cursor: pointer;
        font-size: 20px;

        &:hover {
            font-size: 23px;
        }   
    }
}

.confirm-modal__overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    backdrop-filter: blur(4px);
    z-index: 900;

    .confirm-modal {
        width: 450px;
        height: 140px;
        max-width: 80vw;
        padding: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        border-radius: 10px;
        background: linear-gradient(10deg, $color-1, $color-2);

        color: $color-3;

        text-align: center;

        .confirm-modal__buttons {

          display: flex;
          justify-content: space-around;
          width: 100%;
          
        }
    }
}

.admin-page__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    height: calc(100% - 30px);
    

    .slick-slider {
        width: 80%;

        @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        }

        .slick-slide > div {
            margin: 0 10px;
        }
    }


    .add-property {      
        background-color: rgb(255, 255, 255);

        border-radius: 10px;

        display: flex !important;
        align-items: center;
        justify-content: center;

        width: 70vw;
        height: 80vh;
        min-height: 450px;

        div {
            cursor: pointer;
            font-size: 600%;
            color: $color-2;

            &:hover {
                transform: scale(1.1);
            }   
        }
      
        @media screen and (max-width: 960px) {
          width: 75vw;
        }
    }
}