.home__video-wrapper {
    .home__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        bottom: 0;
        z-index: -2;

        opacity: 40%;

        
    }

}

.hidden {
    display: none;
}

.home__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

    h2 {
        color: $color-3;
        font-size: calc(20px + 2vw);

        margin-bottom: 40px;

        @media screen and (max-width: 960px) {
            margin-bottom: 60px;
            line-height: 40px;
        }
    }

}