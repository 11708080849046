.services {
    display: flex;
    justify-content: space-around;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    color: $color-2;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        position: relative;
        top: 0;
        left: 0;
        transform: inherit;
        margin-top: 80px;

        .col {
            width: 100%;
        }
    }

    .services__title {
        text-align: left;
        max-width: 90%;

        @media screen and (max-width: 960px) {
            text-align: center;
        }

        h2 {
            font-size: calc(18px + 1.4vw);
            color: $color-3;
            margin-bottom: 40px;
            line-height: calc(30px + 1.4vw);

            @media screen and (max-width: 960px) {
                margin: 10px;
                margin-bottom: 40px;
            }
        }
    }
    
    .services__info {
        @media screen and (max-width: 960px) {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .service {
            background-color: rgb(255, 255, 255);
            backdrop-filter: opacity(0%);
            margin: 20px 0;
            padding: 15px 30px;
            border-radius: 10px;
            width: 45vw;
            box-shadow: 2px 2px 5px rgb(32, 32, 32);
            position: relative;
            overflow: hidden;
            line-height: 17px;

            display: flex;
            align-items: center;

            
            .test {
                margin-right: 40px;

                @media screen and (max-width: 960px) {
                    margin-right: 20px
                }
            }

            @media screen and (max-width: 960px) {
                width: 90%;
                padding: 15px 20px;
                margin: 10px auto;
            }

            @keyframes navProject {
                to {
                    transform: translateX(0);
                }
            }
        
            &.estimation {
                margin-top: 40px;
                transform: translateX(50%);
                animation: navProject 1s ease forwards;
            }
            &.money {
                transform: translateX(55%);
                animation: navProject 1.2s ease forwards;
            }
            &.frame {
                transform: translateX(60%);
                animation: navProject 1.4s ease forwards;
            }
            &.effectiveness {
                transform: translateX(65%);
                animation: navProject 1.6s ease forwards;
            }

            h3 {
                margin-bottom: 10px;
                font-size: calc(15px + 0.390625vw);
            }

            p {
                font-size: calc(14px + 0.390625vw);
            }

            .img {
                position: absolute;
                z-index: -1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                right: 0;
                filter: opacity(45%);
            }
        }
    }
}