:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.3s ease-out;
    border: 3px solid;
    

    &.btn--light {
        background-color: transparent;
        color: $color-3;
        border-color: $color-3;

        &.btn--primary:hover {
            background-color: $color-4;
            color: $color-3;
            border-color: $color-4;
        }

        &.btn--secondary:hover {
            background-color: $color-3;
            color: $color-2;
            border-color: $color-3;
        }
    }

    &.btn--dark {
        background-color: transparent;
        color: $color-2;
        border-color: $color-4;
        
        &.btn--primary:hover {
            background-color: $color-3;
            color: $color-2;
            border-color: $color-3;
        }

        &.btn--secondary:hover {
            background-color: $color-4;
            color: $color-3;
            border-color: $color-4;
        }
    }

    &.btn-medium {
        padding: 8px 20px;
        font-size: 18px;
    }

    &.btn--large {
        padding: 12px 26px;
        font-size: 20px;
    }

    &.btn--mobile {
        text-align: center;
        width: 80%;
        text-decoration: none;
        font-size: 24px;
        padding: 14px 20px;
    }

    &.btn--wide {
        padding: 12px 64px;
        font-size: 20px;
        font-weight: bold;
    }

}