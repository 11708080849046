.properties {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 3%;

  // height: 100vh;
  min-height: 568px;

  .properties__empty {
    background-color: $color-3;
    padding: 30px 20px;
    border-radius: 10px;
    margin: 20px;

    text-align: center;
  }
}
