.admin-connect {
    backdrop-filter: opacity(0%);
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;
    min-height: 568px;

    .img {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        right: 0;
        filter: opacity(50%);
    }

    .admin-connect__data {

        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 90%;
        text-align: center;

        position: relative;

        background-color: $color-3;
        padding: 20px 30px;
        border-radius: 10px;

        h3 {
            font-size: calc(16px + 1.4vw);
            color: $color-2;
        }

        .admin-connect__data-buttons {
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin: 30px;

            @media screen and (max-width: 960px) {
                flex-direction: column;

                .btn {
                    margin: 10px
                }
            }
        }
    }

    .admin-connect__form-overlay {
        position: relative;
        display: none;
        width: 100%;
        height: 100%;

        backdrop-filter: blur(4px);

        &.active {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .admin-connect__modal {

            width: 50%;
            height: 50%;
            display: flex;
            background-color: $color-3;
            border-radius: 4px;

            @media screen and (max-width: 960px) {
                flex-direction: column;
                width: 90%;
                height: 80%;
            }

            .admin-connect__img {
                position: relative;
                width: 55%;
                z-index: 1;

                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 960px) {
                    width: 100%;
                    height: 50%;
                }

                .logo-img {
                    width: 80%;
                    height: 100%;
                    object-fit: contain;
                }
            }
    
            .admin-connect__form {
                width: 45%;
                height: 100%;
                position: relative;
                background: linear-gradient($color-1, $color-2);
                border-radius: 0 4px 4px 0;

                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 960px) {
                    width: 100%;
                    height: 50%;
                    border-radius: 0 0 4px 4px;
                }
    

                .sign-up-form {
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                     .input {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        margin: 5px;

                        color: $color-2;

                        @media screen and (max-width: 960px) {
                            margin: 2px;
                        }

                        input {
                            background-color: $color-3;
                            border-radius: 4px;
                            border: 1px solid $color-3;
                            padding: 5px;
                            width: 60%;

                            &:focus {
                                border: 1px solid $color-2;
                            }
                        }

                        label {
                            padding: 5px;
                        }
                     }

                     .sign-up-form__buttons {
                        width: 100%;
                        display: flex; 
                        justify-content: space-around;

                        margin: 40px;

                        @media screen and (max-width: 960px) {
                            margin: 10px;
                        }

                     }
                     .sign-up-form__forgot-pwd {
                        position: absolute;
                        bottom: 5px;
                        color: $color-3;
                        font-size: 0.8em;
                        cursor: pointer;
                        text-decoration: underline;
                     }
                }
            }

            .admin-connect__forgot-pwd {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(4px);
                z-index: 900;

                display: flex;
                align-items: center;
                justify-content: center;

                .admin-connect__forgot-pwd-mail {
                    width: 400px;
                    height: 140px;
                    background-color: $color-3;
                    border-radius: 10px;
                    padding: 10px;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .admin-connect__input {
                        display: flex;
                        justify-content: center;

                        input {
                            margin-left: 5px;
                            border: 1px solid $color-2;
                            border-radius: 4px;
                            padding: 3px;
                        }
                    }

                    .admin-connect__buttons {
                        display: flex;
                        justify-content: space-around;
                    }
                }

            }
        }

    }

}