@import "./settings.scss";
@import "react-upload-gallery/dist/style.css";
@import "react-toastify/dist/ReactToastify.css";
@import "./components/_navBar.scss";
@import "./components/_button.scss";
@import "./components/pages/home.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./components/pages/services.scss";
@import "./components/pages/principles.scss";
@import "./components/pages/properties.scss";
@import "./components/pages/contact.scss";
@import "./components/pages/footer.scss";
@import "./components/pages/connectAdmin.scss";
@import "./components/pages/adminPage.scss";
@import "./components/card.scss";
@import "./components/miniCard.scss";
@import "./components/pages/_property.scss";
@import "./components/properyForm";
@import "./components/pages/changePwd.scss";
@import "./components/pages/gestion.scss";
