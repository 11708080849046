.property {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  .property__ruban {
    z-index: 999;
    height: 35px;
    width: 180px;
    background-color: #9c1b21;
    position: absolute;
    top: 128px;
    right: -6px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    white-space: nowrap;
    -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    transform: rotate(35deg);
    &:before {
      box-sizing: border-box;
      border-style: solid;
      border-color: transparent;
      border-width: 37px;
      border-left-width: 53px;
      border-right-width: 26px;
      content: "";
      display: block;
      left: -49px;
      position: absolute;
      width: 141%;
      border-top-width: 0px;
      border-bottom-color: #9c1b21;
      top: -2px;
      z-index: -1;
    }
  }

  .property__img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .slick-track {
      display: flex;
    }
    .slick-track .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }

    .slick-slider {
      max-width: 40vw;

      @media screen and (max-width: 960px) {
        max-width: 80vw;
      }
    }
  }

  .property__data {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 5vw;

    color: $color-3;

    position: relative;
    height: fit-content;

    @media screen and (max-width: 960px) {
      width: 100%;
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .data__title {
      font-size: calc(14px + 1vw);
      font-weight: bold;
      text-align: center;
    }

    .data__description {
      font-size: calc(8px + 0.5vw);
      margin: 20px 0;
      text-align: justify;
      text-justify: inter-word;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 5px;

      @media screen and (max-width: 960px) {
        max-height: 100px;
      }
    }

    .data__price {
      font-size: calc(10px + 1vw);
      border: 2px solid $color-3;
      padding: 2vh;
      text-align: center;
      border-radius: 10px;
      margin: 5vh 0;
    }

    .data__infos {
      background-color: white;
      border-radius: 10px;
    }

    .data__info {
      display: flex;
      justify-content: space-around;
      text-align: center;
      align-items: center;

      color: $color-2;

      // margin-top: 30px;

      .info-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        font-size: calc(0.7em + 0.5vw);
        padding: 10px;

        .svg-container {
          display: flex;
          align-items: flex-end;

          .svg {
            width: calc(20px + 1vw);
            padding-bottom: 10px;

            &.peb-svg {
              width: calc(30px + 1vw);
            }
          }
        }

        &.data__peb {
          font-weight: bolder;
        }
      }
    }

    .data__address {
      color: $color-2;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding-top: 2vh;
      position: relative;

      .svg-container {
        align-items: center;
        margin: 10px 0;

        .svg {
          padding: 0;
          margin-right: 10px;
          width: 17px;
        }
      }

      p {
        font-size: 0.8em;
      }
    }

    .property-card__buttons {
      display: flex;
      flex-wrap: wrap;

      position: relative;

      width: 100%;
      align-items: center;
      justify-content: space-around;

      .btn {
        margin: 3px;
      }
    }
  }
}
