.contact {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px;
    min-height: 600px;
    height: 100vh;

    overflow: scroll;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        padding: 10px;
        padding-top: 90px;
    }

    .contact__list {
        width: 50%;
        text-align: left;

        @media screen and (max-width: 960px) {
            width: 95%;
            margin: auto;
            text-align: center;
        }

        .contact__list-title {
            font-size: calc(14px + 1.2vw);
            color: $color-3;
            margin-bottom: 40px;
            line-height: calc(30px + 1.6vw);

            @media screen and (max-width: 960px) {
                margin-bottom: 20px;
            }
        }

        .contact__list-text {
            color: white;
            font-size: calc(14px + 0.5vw);
            line-height: calc(20px + 1vw);

            @media screen and (max-width: 960px) {
                margin-bottom: 20px;
            }
        }

        .contact__list-items {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            

            .contact__list-item {
                background-color: $color-3;
                border-radius: 10px;
                color: $color-2;
                width: 260px;
                height: 45px;
                margin: 10px;
                display: flex;
                align-items: center;
                justify-content: center;                

                @media screen and (max-width: 960px) {
                    width: 90%;
                    height: 40px;
                    width: 40%;
                }
            }
        }

    }

    .contact__form-buttons {

        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: space-around;

        width: 40%;
        height: 60vh;

        @media screen and (max-width: 960px) {
            margin: 10px;
            width: 95%;
            height: 50vh;
        }

        .img {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            right: 0;
            filter: opacity(45%);
        }

        .contact__right {

            display: none;

            &.active {
                display: flex;
                flex-direction: column;
            }

            .contact__form-introduction {
                color: $color-3;
                font-size: calc(14px + 0.390625vw);
                margin-bottom: 30px;
            }
    
            .contact__buttons {
                display: flex;
                flex-direction: column;

                align-items: flex-end;
                


                .contact__button {

                    display: flex;
                    margin: 15px;
                    cursor: pointer;
                    width: 85%;

                    @media screen and (max-width: 960px) {
                        width: 95%;
                        margin: 15px auto;
                    }

                    .contact__button-logo {
                        background-color: $color-3;
                        padding: 4px 6px;
                        border-radius: 10px;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .contact__button-children {
                        background-color: $color-3;
                        border-radius: 10px;
                        color: $color-2;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding-left: 50px;
                        font-size: larger;
                        font-weight: bold;

                        @media screen and (max-width: 960px) {
                            padding: 10px;
                            justify-content: center;
                        }

                        &:hover {
                            background-color: $color-1;
                        }
                    }
                }
    
                .btn {
                    margin: 15px 0;
                }
            }
        }


        .contact__form {
            display: none;

            &.active {
                display: block;
                text-align: center;
                margin: auto;
            }

            .input {
                margin: 10px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 400px;

                @media screen and (max-width: 960px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                input {
                    width: 200px;
                    padding: 5px;
                    border-radius: 4px;
                    border: 2px solid $color-2;
                    margin: 0 10px;

                    &:focus {
                        border: 2px solid $color-3
                    }
                }

                label {
                    color: $color-3;
                    font-weight: bold;
                }
            }

            .buttons {
                width: 50%;
                margin: auto;

                margin-top: 30px;
                display: flex;
                justify-content: space-around;

                .btn {
                    margin: 10px;
                }
            }

            .contact__form-footer {
                font-size: small;
                
                bottom: 10px;
                color:$color-3;
            }
        }
    }

    
}