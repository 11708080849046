.minicard {
  max-width: 400px;
  width: 80%;
  aspect-ratio: 1/1;
  min-height: 360px;
  background-color: white;
  border-radius: 30px;
  margin: 20px;
  position: relative;

  &:hover {
    outline: 1px solid $color-1;
    cursor: pointer;
  }

  .property-card__ruban {
    z-index: 89;
    height: 35px;
    width: 180px;
    background-color: #9c1b21;
    position: absolute;
    top: 48px;
    right: -6px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    white-space: nowrap;
    -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    transform: rotate(35deg);
    &:before {
      box-sizing: border-box;
      border-style: solid;
      border-color: transparent;
      border-width: 37px;
      border-left-width: 53px;
      border-right-width: 26px;
      content: "";
      display: block;
      left: -49px;
      position: absolute;
      width: 141%;
      border-top-width: 0px;
      border-bottom-color: #9c1b21;
      top: -2px;
      z-index: -1;
    }
  }

  .card_img_container {
    width: 100%;
    height: 60%;
    background-size: cover;
    border-radius: 30px 30px 0 0;
  }

  .card_infos {
    padding: 5%;

    .card_title {
      font-weight: 800;
      padding-bottom: 5%;
    }

    .card_price {
      font-size: larger;
      padding-bottom: 5%;

      @media screen and (max-width: 960px) {
        font-size: medium;
      }
    }
  }

  .card_pics {
    display: flex;
    justify-content: space-between;

    .pics_infos {
      width: 100%;
      display: flex;
      align-items: center;

      .pic {
        width: 20%;
        margin-right: 5%;
      }
    }
  }
}
