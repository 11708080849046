.gestion {
  .bulletPoint {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    text-align: left;

    span {
      margin-right: 0.5rem;
    }
  }
  h1 {
    color: white;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  .line {
    margin: auto;
    width: 5rem;
    height: 4px;
  }

  .advantages {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    img {
      width: 40rem;
      margin: 5rem;
      border-radius: 10px;
    }

    .text {
      color: white;
      font-size: 1.2em;

      h2 {
        padding-bottom: 1rem;
      }
      ul {
        li {
          display: flex;
          align-items: center;
          margin-top: 1rem;

          span {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .working {
    background-color: #e0e1e1;
    padding: 2rem;
    h2 {
      text-align: center;
      padding: 3rem;
      padding-bottom: 0.8rem;
    }

    .line {
      background-color: black;
      margin-bottom: 3rem;
    }

    .badges {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .badge {
        margin: 1rem;
        background-color: white;
        width: fit-content;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 10px 10px 15px gray;
        overflow-y: scroll;
        width: 25rem;
        height: 48rem;
        text-align: center;

        img {
          margin: auto;
        }

        h3 {
          padding: 1rem;
        }
      }
    }
  }

  .formules {
    padding-bottom: 4rem;

    h2 {
      color: white;
      text-align: center;
      padding: 3rem;
      padding-bottom: 0.8rem;
    }

    .line {
      background-color: white;
      margin-bottom: 3rem;
    }

    .packs {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .packWrapper {
      padding: 3px;
      position: relative;
      width: 25rem;
      height: 42rem;
      margin: 2rem;
    }

    .wrapper1 {
      box-shadow: 1px 1px 25px white;
      background: linear-gradient(to right, #353535, #d7d7d7);
    }
    .wrapper2 {
      box-shadow: 1px 1px 25px #7df9ff;

      background: linear-gradient(to right, #7df9ff, #d7d7d7);
    }
    .wrapper3 {
      box-shadow: 1px 1px 25px #d1b000;
      background: linear-gradient(to right, #d1b000, #d7d7d7);
    }

    .pack {
      background-color: white;
      height: 100%;
      padding: 1rem;
      overflow-y: scroll;

      .star {
        position: absolute;
        text-align: center;
        top: -0.4rem;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .header {
        background-color: #e0e1e1;
        box-shadow: 5px 5px 15px gray;
        border-radius: 10px;
        padding: 0.5rem;
        margin-bottom: 1.5rem;
        text-align: center;

        h4 {
          padding-bottom: 0.5rem;
        }
      }

      .core {
        p {
          padding-left: 0.6rem;
        }
      }
    }
  }
}
